<template>
  <div>
    <b-row no-gutters>
      <b-col md="6"
        class="aqz-card-wrapper"
        v-for="addr in addressesSorted"
        :key="addr.id"
      >
        <b-card
          no-body
        >
          <b-card-header>
            <div v-if="addr.default_billing" v-t="'BillingAddress'"></div>
            <div v-if="addr.default_shipping" v-t="'ShippingAddress'"></div>
            <div v-if="!addr.default_shipping && !addr.default_billing" v-t="'addressForm.addAddrTitle'"></div>
          </b-card-header>
          <b-card-body>
            {{addr.firstname}} {{addr.lastname}}<br>
            {{addr.street.join(', ')}}<br>
            {{addr.city}}{{addr.region ? ', ' + addr.region.region : ''}}, {{addr.postcode}}<br>
            {{addr.country_code}}<br>
            <template v-if="addr.telephone">
              <a :href="`tel:${addr.telephone}`" class="bcm-link bcm-link-primary"> T: {{addr.telephone}}</a>
            </template>
          </b-card-body>

          <b-card-footer>
            <button type="button" v-if="!addr.default_shipping && !addr.default_billing" class="btn btn-none bcm-link bcm-link-secondary bcm-link-noupper mr-2" v-t="'Delete'" @click="showModalConfirm(addr.id)"></button>
            <router-link :to="{ name: 'editAddress', params: { id: addr.id} }" class="btn btn-none bcm-link bcm-link-secondary bcm-link-noupper" v-t="'Edit'"></router-link>
          </b-card-footer>
        </b-card>
      </b-col>

      <b-col md="6" class="aqz-card-wrapper">
        <b-card no-body>
            <b-card-header>
              <template v-if="addresses.length === 0">
                {{$t('addressForm.noAddrTitle')}}
              </template>
              <template v-else>
                {{$t('addressForm.addAddrTitle')}}
              </template>
            </b-card-header>
            <b-card-body>
              <template v-if="addresses.length === 0">
                {{$t('addressForm.noAddrBody')}}
              </template>
              <template v-else>
                {{$t('addressForm.addAddrBody')}}
              </template>
            </b-card-body>
          <b-card-footer>
            <router-link :to="{ name: 'editAddress', params: { id: 'new'} }" class="btn btn-none bcm-link bcm-link-primary" v-t="'Add'"></router-link>
          </b-card-footer>
        </b-card>
      </b-col>

    </b-row>
    <modal-confirm
      :modal-show="modalShowConfirm"
      :message="$t('messageRemoveAddr')"
      :options="editObj"
      @updateModalConfirm="updateModalConfirm"
      @cancel="updateModalConfirm"
      @confirm="removeAddress"
    />
  </div>
</template>

<script>
import { DefaultAddress } from '@/store/modules/checkout/defaultValues'
import ModalConfirm from '@/components/Modal/ModalConfirm.vue'
import { toast } from '@/modules/notifications'

export default {
  props: {
    addresses: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      editObj: {
        index: null,
        address: { ...DefaultAddress }
      },
      modalShowConfirm: false
    }
  },
  computed: {
    hasAddresses () {
      return this.addresses.length > 0
    },
    addressesSorted () {
      let sorted = []
      this.addresses.forEach(addr => {
        if (addr.default_shipping) {
          sorted.splice(0, 0, addr)
        } else if (addr.default_billing) {
          if (sorted.length > 0 && sorted[0].default_shipping) {
            sorted.splice(1, 0, addr)
          } else {
            sorted.splice(0, 0, addr)
          }
        } else {
          sorted.push(addr)
        }
      })
      return sorted
    }
  },
  methods: {
    showModalConfirm (index) {
      this.editObj.index = index
      this.modalShowConfirm = true
    },
    updateModalConfirm (value) {
      this.modalShowConfirm = value
    },
    removeAddress (obj) {
      this.modalShowConfirm = false
      this.$store.commit('ui/showLoader')
      this.$store.dispatch('user/deleteAddress', obj.index)
        .then(user => {
          this.$store.commit('ui/hideLoader', false)
          toast.success(this.$t('Change successful!'))
        })
        .catch(res => {
          this.$store.commit('ui/hideLoader', false)
          toast.error(this.$t(res.body.result))
        })
    }
  },
  components: {
    ModalConfirm
  }
}
</script>
