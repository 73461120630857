<template>
  <div class="personal-detail">
    <modalTerms v-model="modalTerms.open" :pageID="modalTerms.pageID" v-on:close="openModalTerms ({open: false, pageID: ''})"></modalTerms>
    <b-form class="personal-detail__form" @submit.prevent="handleSubmit">
      <b-row>
        <b-col md="6" class="order-1 order-md-0">
          <b-form-group id="groupTitle" >
            <base-select
              name="title"
              v-validate="'required'"
              required
              v-model="form.prefix"
              :options="prefixOptions"
              :validations="[
                {
                  condition: errors.has('title'),
                  text: errors.first('title')
                }
              ]"
              :placeholder="'*' + $t('address.Title')"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" class="align-self-start text-right mb-4 mb-md-0">
          <div class="mandatory-field">
            <span class="f-very-small cl-black">*{{ $t('mandatory-field') }}</span>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group id="groupFirstname" >
            <base-input
              name="firstname"
              v-validate="'required'"
              v-model="form.firstname"
              :placeholder="'*' + $t('address.FirstName')"
              type="text"
              :readonly="readOnly"
              :validations="[
                {
                  condition: errors.has('firstname'),
                  text: errors.first('firstname')
                }
              ]"
            >
            </base-input>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group id="groupLastname" >
            <base-input
              name="lastname"
              v-validate="'required'"
              v-model="form.lastname"
              :placeholder="'*' + $t('address.LastName')"
              type="text"
              :readonly="readOnly"
              :validations="[
                {
                  condition: errors.has('lastname'),
                  text: errors.first('lastname')
                }
              ]"
            >
            </base-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group id="groupEmail">
            <base-input
              name="email"
              v-validate="'required|email'"
              v-model="form.email"
              :placeholder="'*' + $t('address.Email')"
              type="text"
              :readonly="readOnly"
              :validations="[
                {
                  condition: errors.has('email'),
                  text: errors.first('email')
                }
              ]"
            >
            </base-input>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group id="groupDoB">
            <input-dob
              id="dob"
              :oneinput="true"
              v-model="form.date_of_birth"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group id="groupChangePassword" class="change-password mt-4">
        <base-check-box
          name="changePassword"
          v-model="changePassword"
          :label="$t('changePassword')"
        />
      </b-form-group>
      <!-- change password section -->
      <b-collapse id="collapse-change-password" v-model="changePassword" >
        <b-row>
          <!-- current password -->
          <b-col md="6">
            <b-form-group id="groupCurrentpassword" >
              <base-input
                name="currentpassword"
                autocomplete="current-password"
                v-validate="changePassword ? 'required' : ''"
                v-model="currentPassword"
                :placeholder="'*' + $t('form.currentPassword')"
                type="password"
                :validations="[
                  {
                    condition: errors.has('currentpassword'),
                    text: errors.first('currentpassword')
                  }
                ]"
              >
              </base-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group id="groupNewpassword" >
              <base-input
                name="newpassword"
                ref="newpassword"
                autocomplete="new-password"
                v-validate="changePassword ? 'required' : ''"
                v-model="form.password"
                :placeholder="'*' + $t('form.newPassword')"
                type="password"
                :validations="[
                  {
                    condition: errors.has('newpassword'),
                    text: errors.first('newpassword')
                  }
                ]"
              >
              </base-input>
            </b-form-group>
          </b-col>
          <!-- check new password -->
          <b-col md="6">
            <b-form-group id="groupCheckNewpassword" >
              <base-input
                name="checknewpassword"
                autocomplete="new-password"
                v-validate="changePassword ? 'required|confirmed:newpassword' : ''"
                v-model="checkNewPassword"
                :placeholder="'*' + $t('form.checknewPassword')"
                type="password"
                :validations="[
                  {
                    condition: errors.has('checknewpassword'),
                    text: errors.first('checknewpassword')
                  }
                ]"
              >
              </base-input>
            </b-form-group>
          </b-col>
        </b-row>
      </b-collapse>
      <!-- Newsletter -->
      <section class="newsletter-form mt-7">
        <h3 class="title">{{ $t('Newsletter') }}</h3>
        <p class="subtitle">
          <span v-t="'newsletter_subtitle'" v-if="isSubscribed"/>
        </p>
        <b-form-group id="groupNewsletterSubscribe">
          <base-check-box
            name="newsletterSubscribe"
            v-model="form.is_subscribed"
            :validations="[
              {
                condition: errors.has('newsletterSubscribe'),
                text: errors.first('newsletterSubscribe')
              }
            ]"
            :label="labelLinkPrivacy"
            @label-clicked="openModalTerms({ open: true, pageID: 'terms1' })"
          />
        </b-form-group>
      </section>

      <b-button type="submit" variant="primary" style="display:none">{{ $t('Save') }}</b-button>
    </b-form>
  </div>
</template>

<script>
import BaseInput from '@/components/Form/BaseInput'
import BaseCheckBox from '@/components/Form/BaseCheckBox'
import BaseSelect from '@/theme/components/Form/BaseSelect'
import { EventBus } from '@/helpers'
import InputDob from '@/theme/components/Form/Dob'
import _ from 'lodash'
import { toast } from '@/modules/notifications'
import Config from '@/config'
import Logger from '@/services/Logger'
import { pathTranslate } from '@/helpers/routeHelpers'
import ModalTerms from '@/theme/components/Modal/Terms'

const modelForm = {
  prefix: '',
  firstname: '',
  lastname: '',
  email: '',
  date_of_birth: '',
  password: '',
  is_subscribed: false
}
export default {
  inject: ['$validator'],
  data () {
    return {
      form: { ...modelForm },
      currentPassword: '',
      checkNewPassword: '',
      isModify: true,
      readOnly: false,
      changePassword: false,
      is_subscribed2: false,
      modalTerms: {
        open: false,
        pageID: ''
      }
    }
  },
  props: {
    customer: {
      type: Object,
      default: () => ({ ...modelForm })
    }
  },
  computed: {
    prefixOptions () {
      const _this = this
      return Config.Theme.forms.prefixValues.map(item => _this.$t('form.prefix.' + item))
    },
    isSubscribed () {
      return this.customer && this.customer.is_subscribed
    },
    labelLinkPrivacy () {
      let linkPrivacy = this.$router.resolve({ name: 'genericTerms', params: { slug: pathTranslate({ path: 'privacy-policy' }), lang: this.$store.state.ui.storeViewCode } }).href
      return this.$t('termsMarketing', { linkPrivacy })
    }
  },
  methods: {
    openModalTerms ({ open, pageID }) {
      this.modalTerms = {
        open,
        pageID
      }
    },
    initForm () {
      this.$nextTick(function () {
        _.assign(this.form, this.customer)
        this.$validator.reset()
      })
    },
    editPersonalInfo () {
      this.isModify = true
      this.readOnly = false
    },
    handleSubmit () {
      const self = this
      self.$validator.validateAll().then((isValid) => {
        if (isValid) {
          self.$store.commit('ui/showLoader')
          // if change password
          if (!self.changePassword) {
            self.form.password = ''
          }
          const promArr = []
          promArr.push(self.$store.dispatch('user/update', self.form))
          if (self.changePassword) {
            promArr.push(self.$store.dispatch('user/changePass', {
              currentPassword: self.currentPassword,
              newPassword: self.form.password
            }))
          }
          Promise.all(promArr)
            .then(user => {
              // this.readOnly = true
              // this.isModify = false
              self.$store.commit('ui/hideLoader', false)
              toast.success(self.$t('Change successful!'))
              self.$validator.reset()
            })
            .catch(error => {
              Logger.error(error)
              this.$store.commit('ui/hideLoader', false)
              error && error.message && toast.error(self.$t(error.message))
            })
        } else {
          toast.error(self.$t('Remember to fill in all the fields!'))
        }
      })
    }
  },
  mounted () {
    if (this.customer) {
      _.assign(this.form, this.customer)
    }
    EventBus.$on('user:update', this.initForm.bind(this))
  },
  destroyed () {
    EventBus.$off('user:update', this.initForm.bind(this))
  },
  components: {
    BaseInput,
    BaseSelect,
    BaseCheckBox,
    InputDob,
    ModalTerms
  }
}
</script>
